// import { Timeline } from 'react-twitter-widgets';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Button from '../reusables/Button';
import CalendarPanel from '../CalendarPanel';
import FeaturedArticle from '../FeaturedArticle';
import SectionTitle from '../reusables/SectionTitle';
import SocialIcons from '../SocialIcons';
import instagramIcon from '../../assets/instagramIcon.svg';

const Placeholder = styled.div({
  backgroundColor: 'aliceBlue',
  padding: 10,
  height: 400,
});
const Container = styled.div({
  fontSize: 14,
  lineHeight: 1.1,
});
const Articles = styled.div({});

const InstaContainer = styled.div({});

const ButtonContainer = styled.div({});

function Sidebar({ events, articles }) {
  return (
    <Container>
      <SectionTitle bold extra={<SocialIcons inverted columnGap={4} />}>
        Social
      </SectionTitle>
      <CalendarPanel events={events} />
      <SectionTitle>Media</SectionTitle>
      <Articles>
        {articles.map(({ node }) => {
          return <FeaturedArticle {...node} isPress={true} key={node.title} />;
        })}
      </Articles>
      <InstaContainer>
        <SectionTitle>Instagram</SectionTitle>
        <ButtonContainer>
          <Button
            as={Link}
            to="https://www.instagram.com/beteendelabbet/"
            target="_blank"
          >
            <img
              css={{ filter: 'invert(80%)' }}
              alt="Instagram-ikon"
              src={instagramIcon}
            />
            FÖLJ OSS PÅ INSTAGRAM
          </Button>
        </ButtonContainer>
      </InstaContainer>
    </Container>
  );
}

export default Sidebar;
