import styled from 'styled-components';

import { breakpoints } from '../../lib/constants';

const PageWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 320px',
  columnGap: 120,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    gridTemplateColumns: '1fr',
  },
  [`@media (max-width: 1000px)`]: {
    columnGap: 50,
  }
});

export default PageWrapper;
