import React from 'react';
import styled from 'styled-components';

import SectionTitle from './reusables/SectionTitle';
import smallBlueBlobImage from '../assets/smallBlueBlob.svg';

const Description = styled.div({
  p: {
    margin: 0,
    marginBottom: 5,
  },
});
const Events = styled.div({});
const Event = styled.div({
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
  columnGap: 10,
  alignItems: 'start',
  marginBottom: 30,
});

const DayNumber = styled.div({
  backgroundImage: `url(${smallBlueBlobImage})`,
  backgroundRepeat: 'no-repeat',
  width: 46,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontSize: 18,
  marginBottom: 5,
});

const EventDate = styled.div({
  textTransform: 'uppercase',
  textAlign: 'center',
});

export default function CalendarPanel({ events }) {
  return (
    <>
      <SectionTitle css={{ paddingTop: 0 }}>Kalendarium</SectionTitle>
      <Events>
        {events.map(({ node }, i) => {
          const [day, month] = node.date.split(' ');
          return (
            <Event key={node.link + i}>
              <EventDate>
                <DayNumber>{day}</DayNumber>
                <div>{month}</div>
              </EventDate>
              <Description>
                <div
                  dangerouslySetInnerHTML={{
                    __html: node.description.childMarkdownRemark.html,
                  }}
                />
                <a
                  href={node.link}
                  target={
                    /https:\/\/beteendelabbet/.test(node.link)
                      ? undefined
                      : '_blank'
                  }
                  css={{
                    textDecoration: 'underline',
                  }}
                >
                  {node.linkText}
                </a>
              </Description>
            </Event>
          );
        })}
      </Events>
    </>
  );
}
