import React from 'react';
import styled from 'styled-components';

import { fonts } from '../../lib/constants';

const Title = styled.h4({
  fontFamily: fonts.regular,
  fontSize: 14,
  textTransform: 'uppercase',
  margin: 0,
});

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 14,
  padding: '30px 0',
});

export default ({ children, extra, bold, ...rest }) => (
  <Container {...rest}>
    <Title css={{ fontFamily: bold ? fonts.bold : fonts.regular }}>
      {children}
    </Title>
    {extra}
  </Container>
);
