import { graphql } from 'gatsby';
import React from 'react';

import AppChrome from '../components/AppChrome';
import ContentWrapper from '../components/reusables/ContentWrapper';
import Main from '../components/latest/Main';
import PageWrapper from '../components/latest/PageWrapper';
import Sidebar from '../components/latest/Sidebar';
import TopNav from '../components/TopNav';

function ArticleIndex({ data }) {
  const {
    allContentfulEvent,
    allContentfulArticle,
    allContentfulSettings,
  } = data;
  return (
    <AppChrome
      title="Nyheter"
      og={{
        title: 'Nyheter',
        description:
          allContentfulSettings.edges[0].node.latestPageHero.description,
        type: 'article',
        image:
          allContentfulSettings.edges[0].node.latestPageHero.image.sizes.src,
      }}
    >
      <TopNav />
      <ContentWrapper>
        <PageWrapper>
          <Main
            heroArticle={allContentfulSettings.edges[0].node.latestPageHero}
            articles={allContentfulArticle.edges}
          />
          <Sidebar
            events={allContentfulEvent.edges}
            articles={allContentfulArticle.edges
              .filter(({ node }) => node.category.slug === 'press')
              .slice(0, 5)}
          />
        </PageWrapper>
      </ContentWrapper>
    </AppChrome>
  );
}

export default ArticleIndex;

export const pageQuery = graphql`
  {
    allContentfulEvent(sort: { fields: [date], order: ASC }) {
      edges {
        node {
          date(formatString: "DD MMM")
          link
          linkText
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulArticle(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          publication
          publishDate(formatString: "DD MMMM YYYY", locale: "sv")
          category {
            title
            slug
          }
          author {
            name
          }
          image {
            sizes(maxWidth: 700, maxHeight: 392, resizingBehavior: FILL) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          description
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSettings {
      edges {
        node {
          latestPageHero {
            id
            title
            slug
            description
            publishDate(formatString: "DD MMMM YYYY", locale: "sv")
            category {
              title
              slug
            }
            author {
              name
            }
            image {
              sizes(maxWidth: 885, maxHeight: 625, resizingBehavior: FILL) {
                ...GatsbyContentfulSizes_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
