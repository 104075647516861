import React, { useState } from 'react';
import styled from 'styled-components';

import { gutters } from '../../lib/constants';
import ArticlePreview from './ArticlePreview';
import Button from '../reusables/Button';
import HeroArticle from './HeroArticle';
import SectionTitle from '../reusables/SectionTitle';

const Container = styled.div({
  marginBottom: gutters.vertical,
  display: 'flex',
  flexDirection: 'column',
  flex: 2,
});
const ArticleList = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: gutters.vertical,
  rowGap: gutters.vertical * 2,
  [`@media (max-width: 1000px)`]: {
    gridTemplateColumns: '1fr',
  },
});

function Main({ articles, heroArticle }) {
  articles = articles.filter(a => a.node.id !== heroArticle.id);
  const [viewAll, setViewAll] = useState(false);
  return (
    <Container>
      <SectionTitle bold>Beteendebloggen</SectionTitle>
      <HeroArticle {...heroArticle} />
      <ArticleList>
        {articles.slice(0, viewAll ? 1000 : 6).map(({ node }) => {
          return <ArticlePreview {...node} key={node.slug} />;
        })}
      </ArticleList>
      {!viewAll && (
        <div css={{ marginTop: 50, textAlign: 'center' }}>
          <Button onClick={() => setViewAll(true)}>
            Fler inlägg
          </Button>
        </div>
      )}
    </Container>
  );
}

export default Main;
