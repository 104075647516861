import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { fonts } from '../lib/constants';

const Title = styled.div({
  fontFamily: fonts.bold,
  marginBottom: 10,
  fontSize: 14,
});
const Category = styled.div({
  fontSize: 14,
  textDecoration: 'underline',
  marginBottom: 5,
});
const StyledImg = styled(Img)({
  width: '100%',
});
const RightCol = styled.div({});

const Item = styled(Link)({
  display: 'grid',
  gridTemplateColumns: '160px 1fr',
  columnGap: 20,
  marginBottom: 25,
});

export default function FeaturedArticle({
  title,
  image,
  publication,
  slug: articleSlug,
  category: { title: categoryTitle, slug: categorySlug },
  isPress = false,
}) {
  return (
    <Item to={`/${categorySlug}/${articleSlug}`}>
      <StyledImg alt={title} sizes={image.sizes} />
      <RightCol>
        <Category>{isPress ? publication : categoryTitle}</Category>
        <Title>{title}</Title>
      </RightCol>
    </Item>
  );
}
