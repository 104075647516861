import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { breakpoints, fonts } from '../../lib/constants';

const Container = styled(Link)({
  width: '100%',
  marginBottom: 25,
});
const StyledImg = styled(Img)({
  marginBottom: 20,
});
const Title = styled.div({
  fontFamily: fonts.bold,
  marginBottom: 10,
  fontSize: 24,
});
const Description = styled.div({
});
const LeftCol = styled.div({
});
const Text = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 20,
  [`@media (max-width: 1000px)`]: {
    display: 'block',
  },
});
const PublishDate = styled.div({
  fontSize: 14,
  lineHeight: '15px',
  marginBottom: 5,
});

function HeroArticle({
  description,
  title,
  image,
  publishDate,
  author: { name },
  slug: articleSlug,
  category: { title: categoryTitle, slug: categorySlug },
}) {
  return (
    <Container to={`/${categorySlug}/${articleSlug}`}>
      <StyledImg alt={title} sizes={image.sizes} />
      <Text>
        <LeftCol>
          <PublishDate>
            {publishDate.toUpperCase()} - BY {name.toUpperCase()}
          </PublishDate>
          <Title>{title}</Title>
        </LeftCol>
        <Description>{description}</Description>
      </Text>
    </Container>
  );
}

export default HeroArticle;
